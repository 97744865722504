export const Questioning = ({
  width = "101",
  height = "100",
  minX = 0,
  minY = 0,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`${minX} ${minY}  101 100`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M89.4294 78.0535C88.2379 79.8298 87.0464 82.0503 85.4577 83.8265C69.968 103.809 33.4282 101.145 17.5414 81.1623C-24.1616 28.3186 70.3651 -29.8542 94.5926 29.6504C100.947 45.1926 98.1672 63.8434 89.4294 78.0535Z"
      fill="black"
      stroke="black"
      strokeWidth="4"
    />
    <path
      d="M84.3737 75.5055C83.1822 77.2818 81.9907 79.5023 80.402 81.2786C64.9123 101.261 28.3726 98.5972 12.4857 78.6143C-29.2172 25.7707 65.3095 -32.4022 89.5369 27.1024C95.8917 42.6447 93.1115 61.2955 84.3737 75.5055Z"
      fill="white"
      stroke="black"
      strokeWidth="4"
    />
    <path
      d="M36.0657 30.79C38.3344 29.5014 38.7742 29.1925 41.6713 27.9683C43.6325 27.1396 45.6048 26.3303 47.6187 25.6469C50.4848 24.6744 54.8981 23.0483 58.2048 23.7558C60.5679 24.2614 60.9308 25.9026 61.0467 27.9983C61.2701 32.0389 59.2925 35.9796 57.4528 39.3951C54.1177 45.5873 49.605 50.9227 45.9682 56.8955C45.2492 58.0763 44.6636 59.082 44.3569 60.4276C44.0552 61.751 44.5505 62.0784 44.8549 63.2092"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M48.0193 74.5555C47.7021 75.2453 46.1948 77.7166 47.7166 77.7875C48.9006 77.8426 49.6014 77.7965 49.6014 76.4767C49.6014 75.8886 49.2312 75.5429 48.7224 75.4561"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
