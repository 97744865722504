import i18n from "i18next";
import axios from "axios";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { getUserLocales } from "get-user-locale";

const SUPPORTED_LANGUAGES = ["es", "ca"];
const DEFAULT_LANGUAGE = "ca";

const getLocaleFor = (currentUser) => {
  if (currentUser) {
    return currentUser.preferred_locale;
  }

  const userLocales = getUserLocales().map((locale) =>
    locale.split("-")[0].toLowerCase()
  );

  const browserLocale = userLocales.find((locale) =>
    SUPPORTED_LANGUAGES.includes(locale)
  );

  return browserLocale || DEFAULT_LANGUAGE;
};

export const initializeI18n = async (currentUser, localeFromUrl) => {
  const locale = localeFromUrl || getLocaleFor(currentUser);

  axios.defaults.headers.common["X-Application-Locale"] = locale;

  await i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // pass the i18n instance to the react-i18next components.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: DEFAULT_LANGUAGE,
      whitelist: SUPPORTED_LANGUAGES,
      debug: process.env.REACT_APP_ENV !== "production",
      lng: locale,

      react: {
        useSuspense: true,
      },

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },

      backend: {
        loadPath: (lngs, namespaces) => {
          if (process.env.REACT_APP_FORM_BUILD) {
            return `${axios.defaults.baseURL}/locales/${lngs[0]}/`;
          } else {
            return `/locales/${lngs[0]}/${namespaces[0]}.json`;
          }
        },
        queryStringParams: {
          v: process.env.REACT_APP_ANSISTRANO_VERSION || "0",
        },
      },
    });
};
