import { useLinesDerivedState } from "hooks/useLinesDerivedState";
import { InternetTariffPicker } from "../components/internet/InternetTariffPicker";
import { useStore } from "hooks/useStore";
import { typeToCollection } from "lib/helpers/urls";
import { useMemo } from "react";

export const InternetTariffs = ({ tariffs, ...props }) => {
  const internetTariffs = useMemo(() => {
    return tariffs.filter((tariff) => tariff.category !== "mobile");
  }, [tariffs]);

  const { updateLineAt } = useStore();
  const { internetLines } = useLinesDerivedState();

  function onChange(matchedTariff) {
    if (matchedTariff) {
      updateLineAt(matchedTariff, "internet", 0);
    } else {
      updateLineAt(typeToCollection("internet"), "internet", 0);
    }
  }
  return (
    <InternetTariffPicker
      internetTariffs={internetTariffs}
      onChange={onChange}
      initialTariffCode={internetLines[0]?.code}
      {...props}
    />
  );
};
