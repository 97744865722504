import { Box, Typography } from "@mui/material";
import { SuperCard } from "components/SuperCard";

// TODO confirmMode
const Header = ({ confirmMode, title, topRightAction = null, index }) => (
  <Box
    sx={{
      background: "#C7C5C5",
      width: "calc(100% + 2rem)",
      margin: "-1rem",
      borderRadius: "16px 16px 0px 0px",
      display: "flex",
      alignItems: "center",
      padding: "10px",
      justifyContent: "space-between",
    }}
  >
    <Box
      sx={{
        display: "flex",
        gap: 2,
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          width: "24px",
          justifyContent: "center",
          alignItems: "ccenter",
          borderRadius: "50%",
          background: theme.palette.grey[900],
        })}
      >
        <Typography sx={{ color: "white" }} variant="body_bold">
          {index}
        </Typography>
      </Box>
      <Typography variant="body_bold">{title}</Typography>
    </Box>
    {topRightAction}
  </Box>
);

export const SignUpCard = ({ children, ...props }) => {
  return (
    <SuperCard
      sx={{
        border: "none",
        borderRadius: 4,
        maxWidth: "860px",
        width: "100%",
        justifySelf: "center",
      }}
    >
      <Header {...props} />
      <Box sx={{ width: "100%" }}>{children}</Box>
    </SuperCard>
  );
};
