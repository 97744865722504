import { Typography } from "@mui/material";
import { Link } from "components/Link";
import { Footer } from "components/layouts/Footer";

import { Trans } from "react-i18next";

export const SetTariffFooter = () => (
  <Footer>
    <Typography variant="body_small">
      <Trans i18nKey={"funnel.tariffs.internet_and_mobile.footer"}>
        <Link target="_parent" to={"/tariffs/personalized"} />
      </Trans>
    </Typography>
  </Footer>
);
