import { Chip as MaterialChip, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";

const Chip = ({ ...props }) => <MaterialChip {...props} />;

export const WOPermanenceChip = () => {
  const { t } = useTranslation();
  return (
    <Chip
      icon={<CheckIcon />}
      label={
        <Typography variant="body_bold">{t("price_tag.no_binding")}</Typography>
      }
      color="sand"
    />
  );
};
export const WLandlineChip = () => {
  const { t } = useTranslation();
  return (
    <Chip
      label={
        <Typography variant="body_bold">{t("price_tag.w_landline")}</Typography>
      }
      color="green"
    />
  );
};
export const SavingChip = ({ saving }) => {
  const { t } = useTranslation();
  return (
    <Chip
      label={
        <Typography variant="body_bold">{`${saving}% ${t(
          "common.saving"
        )}`}</Typography>
      }
      color="primary"
    />
  );
};
