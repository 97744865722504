function matchByName(groups, tech) {
  return groups.findIndex((element) => element.name === tech.name);
}

function initTech(
  has_landline_phone,
  bandwidth,
  category = "fiber",
  initialBandwidth,
  initialCategory = "fiber",
  initialHasLandLine
) {
  return {
    name: category + (has_landline_phone ? "landline" : ""),
    category: category,
    hasLandLine: has_landline_phone,
    bandwidths: [
      { bandwidth: bandwidth, isSelected: bandwidth === initialBandwidth },
    ],
    isSelected:
      initialCategory === category && initialHasLandLine === has_landline_phone,
  };
}

export function getTechnologicalOffers(tariffs, initialTariff) {
  const initialBandwidth = initialTariff?.bandwidth;
  const initialCategory = initialTariff?.category;
  const initialHasLandLine = initialTariff?.has_landline_phone;
  return tariffs.reduce((groups, tariff) => {
    const technology = {
      ...initTech(
        tariff.has_landline_phone,
        tariff.bandwidth,
        tariff.category,
        initialBandwidth,
        initialCategory,
        initialHasLandLine
      ),
      data: tariff.data,
    };
    const matchTechno = matchByName(groups, technology);
    if (matchTechno > -1) {
      groups[matchTechno].bandwidths.push({
        bandwidth: tariff.bandwidth,
        isSelected: tariff.bandwidth === initialBandwidth,
      });
      groups[matchTechno].bandwidths.sort((a, b) => a.bandwidth - b.bandwidth);
    } else {
      groups.push(technology);
    }
    return groups;
  }, []);
}

export function getDataSet(tariffs, initialTariff) {
  const initialData = initialTariff?.data;
  const initialMinutes = initialTariff?.minutes;
  return tariffs.reduce((groups, tariff) => {
    const matchOffer = groups.findIndex(
      (element) => element.data === tariff.data
    );
    if (matchOffer > -1) {
      groups[matchOffer].minutes.push({
        minutes: tariff.minutes,
        price: tariff.price,
        isSelected: tariff.minutes === initialMinutes,
      });
    } else {
      groups.push({
        data: tariff.data,
        minutes: [
          {
            minutes: tariff.minutes,
            price: tariff.price,
            isSelected: tariff.minutes === initialMinutes,
          },
        ],
        isSelected: tariff.data === initialData,
      });
    }
    groups.sort((a, b) => a.data - b.data);
    return groups;
  }, []);
}

export function getPackOffers(packs, initialpack) {
  const initialBandwidth = initialpack?.fiber_bandwidth;
  const initialHasLandLine = initialpack?.has_landline_phone;
  const initialMobiles = initialpack?.mobiles_in_pack;
  return packs.reduce((groups, pack) => {
    const technology = {
      ...initTech(
        pack.has_landline_phone,
        pack.fiber_bandwidth,
        undefined,
        initialBandwidth,
        undefined,
        initialHasLandLine
      ),
      mobiles: [
        {
          mobiles: pack.mobiles_in_pack,
          isSelected: pack.mobiles_in_pack === initialMobiles,
        },
      ],
    };
    const matchTechno = matchByName(groups, technology);
    if (matchTechno > -1) {
      const matchBandwidth = groups[matchTechno].bandwidths.findIndex(
        (bandwidth) => bandwidth.bandwidth === pack.fiber_bandwidth
      );
      if (matchBandwidth === -1) {
        groups[matchTechno].bandwidths.push({
          bandwidth: pack.fiber_bandwidth,
          isSelected: pack.fiber_bandwidth === initialBandwidth,
        });
        groups[matchTechno].bandwidths.sort(
          (a, b) => a.bandwidth - b.bandwidth
        );
      }
      const matchMobile = groups[matchTechno].mobiles.findIndex(
        (mobiles) => mobiles.mobiles === pack.mobiles_in_pack
      );
      if (matchMobile === -1) {
        groups[matchTechno].mobiles.push({
          mobiles: pack.mobiles_in_pack,
          isSelected: pack.mobiles_in_pack === initialMobiles,
        });
        groups[matchTechno].mobiles.sort((a, b) => a.mobiles - b.mobiles);
      }
    } else {
      groups.push(technology);
    }
    return groups;
  }, []);
}
