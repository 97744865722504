import { Alert, Box, Typography, useMediaQuery } from "@mui/material";
import { Spinner } from "components/Spinner";
import { useCatalog } from "hooks/queries/useTariffs";
import { useApplicationContext } from "hooks/useApplicationContext";
import { useLinesDerivedState } from "hooks/useLinesDerivedState";
import { useQueryParams } from "hooks/useQueryParams";
import { useStore } from "hooks/useStore";
import { getSignupUrl, openUrl, typeToCollection } from "lib/helpers/urls";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FaqsFooter } from "./components/FaqsFooter";
import { PricePreview } from "./components/PricePreview";
import { InternetTariffs } from "./views/InternetTariffs";
import { MobileTariffs } from "./views/MobileTariffs";
import { PackTariffs } from "./views/PackTariffs";
import { PersonalizedTariffs } from "./views/PersonalizedTariffs";
import { useCoopAgreement } from "hooks/queries/useCoopAgreement";
import { SetTariffFooter } from "./components/packs/SetTariffFooter";
import { RecommendedTariffs } from "./views/RecommendedTariffs";
import { ContractTariff } from "./views/ContractTariff";
function getMinimumLines(type) {
  switch (type) {
    case "mobile":
      return 1;
    case "internet":
      return 1;
    default:
      return 2;
  }
}
const Container = ({ children, footerOffset, isMobile }) => {
  const contentPaddingBottom = isMobile ? 16 : 40;
  return (
    <Box
      sx={{
        p: isMobile
          ? `16px 16px ${contentPaddingBottom + footerOffset}px 16px`
          : `40px 40px ${contentPaddingBottom + footerOffset}px 40px`,
      }}
    >
      {children}
    </Box>
  );
};
export const Tariffs = () => {
  const { type } = useParams();
  const loggedIn = Boolean(useApplicationContext()?.currentUser);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { lines, setLines, removeLine, isSharedLines, isPromotionActive } =
    useStore();
  const { hasSomeLineLoaded, isMultipleLines, hasEmptyLines } =
    useLinesDerivedState();
  const [footerOffset, setFooterOffset] = useState(0);
  const footerRef = useRef(null);
  const contentRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (type === "internet_and_mobile") {
      return;
    }
    setLines(typeToCollection(type));
  }, [type]);

  useEffect(() => {
    const ContentHeight = contentRef?.current
      ? contentRef?.current.clientHeight
      : 0;
    const FooterHeight = footerRef?.current
      ? footerRef.current.clientHeight
      : footerOffset;
    if (ContentHeight > window.innerHeight - FooterHeight) {
      setTimeout(() => {
        window.scroll({
          top:
            contentRef.current.getBoundingClientRect().bottom + window.scrollY,
          behavior: "smooth",
        });
      }, 200);
    }
    setFooterOffset(FooterHeight);
  }, [lines]);

  const {
    isLoading: isLoadingCatalog,
    error: errorLoaingCatalog,
    data: catalog = {},
  } = useCatalog();

  const [showContract, setShowContract] = useState(false);

  const qp = useQueryParams();

  const { setIsPromotionActive } = useStore();

  const isCoopAgreement =
    qp.opting_for_role === "coop_agreement" && qp.coop_agreement_code !== "SC";
  const {
    data: { first_month_promotion: firstMonthPromotion } = {
      name: null,
    },
  } = useCoopAgreement({
    automatically_run: isCoopAgreement,
    coopAgreementCode: qp.coop_agreement_code,
  });

  function delegatedModalOpener() {
    if (loggedIn || qp.opting_for_role) {
      if (isCoopAgreement && firstMonthPromotion) {
        setIsPromotionActive(firstMonthPromotion);
        setShowContract(true);
      } else {
        /*
         *Directly redirect
         */
        openUrl(
          getSignupUrl(
            lines,
            qp.opting_for_role,
            qp.coop_agreement_code,
            true,
            isSharedLines
          )
        );
      }
    } else {
      setShowContract(true);
    }
  }

  if (isLoadingCatalog) return <Spinner />;
  if (errorLoaingCatalog)
    return <Alert severity="error">{t("common.errors.request_failed")}</Alert>;
  if (showContract)
    return (
      <ContractTariff
        onBack={() => setShowContract(false)}
        packs={type === "internet_and_mobile" && catalog.packs}
        lines={lines}
        isSharedLines={isSharedLines}
        isPromotionActive={isPromotionActive}
      />
    );
  return (
    <>
      {type === "recommended" ? (
        <RecommendedTariffs
          packs={catalog.packs}
          onContract={delegatedModalOpener}
        />
      ) : (
        <Container footerOffset={footerOffset} isMobile={isMobile}>
          <div ref={contentRef}>
            <Typography variant={isMobile ? "h4" : "h3"} gutterBottom>
              {t(`funnel.tariffs.${type}.title`)}
            </Typography>
            {(() => {
              if (type === "internet_and_mobile") {
                return (
                  <PackTariffs
                    packs={catalog.packs}
                    onContract={delegatedModalOpener}
                  />
                );
              } else if (type === "mobile") {
                return <MobileTariffs tariffs={catalog.tariffs} />;
              } else if (type === "internet") {
                return <InternetTariffs tariffs={catalog.tariffs} />;
              } else {
                return <PersonalizedTariffs tariffs={catalog.tariffs} />;
              }
            })()}
          </div>
          <Box
            ref={footerRef}
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              zIndex: "1299",
              width: "100%",
            }}
          >
            {hasSomeLineLoaded ? (
              type !== "internet_and_mobile" ? (
                <PricePreview
                  tariffs={lines.filter((line) => line?.code)}
                  isMultiLine={isMultipleLines}
                  minimumLines={getMinimumLines(type)}
                  onContract={delegatedModalOpener}
                  onDeleteTariff={(id, type) => removeLine(id, type)}
                  isContractDisabled={hasEmptyLines}
                />
              ) : (
                <SetTariffFooter />
              )
            ) : (
              <FaqsFooter />
            )}
          </Box>
        </Container>
      )}
    </>
  );
};
