import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  FormControl,
  MenuItem as MaterialMenuItem,
  Select,
  styled,
} from "@mui/material";
import React from "react";
import { InputLabel } from "./InputLabel";
import { StyledInput } from "./StyledInput";
import { useTranslation } from "react-i18next";
import { InputAdornment } from "./InputAdornment";
import { InputErrorLabel } from "./InputErrorLabel";
import { InputSubInfo } from "./InputSubInfo";
import { Field } from "react-final-form";

const StyledMenuItem = styled(MaterialMenuItem)(({ theme }) => ({
  "&.Mui-selected": {
    "backgroundColor": theme.palette.grey[900],
    "color": theme.palette.grey[100],
    "&:hover": { backgroundColor: theme.palette.grey[800] },
  },
  "&:hover": { backgroundColor: theme.palette.grey[200] },
}));

const StyledSelect = styled(({ className, ...props }) => (
  <Select {...props} MenuProps={{ PaperProps: { className } }} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[900]}`,
  borderLeft: `1px solid ${theme.palette.grey[900]}`,
  borderRight: `1px solid ${theme.palette.grey[900]}`,
  borderRadius: "0px 0px 8px 8px",
}));
export const SelectField = ({
  label,
  options,
  i18nPrefix,
  questionLabel,
  infoLabel,
  startIconAdorment,
  endIconAdorment,
  errorLabel,
  id,
  ...props
}) => {
  const { t } = useTranslation();
  const isError = Boolean(errorLabel);
  const getOptions = () => {
    if (!i18nPrefix) {
      return options;
    }

    return options.map((value) => ({
      label: t([i18nPrefix, props.name, value].join(".")),
      value,
    }));
  };
  return (
    <FormControl {...props}>
      <InputLabel
        id={`${id}_label`}
        label={label}
        questionLabel={questionLabel}
      />
      <StyledSelect
        labelId={`${id}_label`}
        id={id}
        input={<StyledInput isError={isError} />}
        IconComponent={() => <KeyboardArrowDownIcon color="inherit" />}
        disableUnderline
        startAdornment={
          startIconAdorment && (
            <InputAdornment position="start" iconAdorment={startIconAdorment} />
          )
        }
        endAdornment={
          (endIconAdorment || isError) && (
            <InputAdornment
              position="end"
              iconAdorment={endIconAdorment}
              isError={isError}
            />
          )
        }
        {...props}
      >
        {getOptions().map(({ value, label }, index) => (
          <StyledMenuItem key={JSON.stringify(value)} value={value}>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {label}
            </div>
          </StyledMenuItem>
        ))}
      </StyledSelect>
      {errorLabel && <InputErrorLabel label={errorLabel} />}
      {infoLabel && <InputSubInfo id={id} label={infoLabel} />}
    </FormControl>
  );
};

const FormField = ({ name, validate, ...props }) => {
  const { t } = useTranslation();
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <SelectField
          {...props}
          name={input.name}
          value={input.value}
          onChange={input.onChange}
          errorLabel={
            meta.submitFailed &&
            meta.error &&
            t(`common.errors.validation.${meta.error}`)
          }
        />
      )}
    </Field>
  );
};

SelectField.FormField = FormField;
