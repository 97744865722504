import axios from "axios";

export async function getCategories(parentRef = "") {
  const { data } = await axios.get(`/knowledge/categories/?parent=${parentRef}`);
  return data;
}

export async function getCategory(categoryRef) {
  const { data } = await axios.get(`/knowledge/categories/${categoryRef}/`);
  return data;
}

export async function getPage(pageRef) {
  const { data } = await axios.get(`/knowledge/pages/${pageRef}/`);
  return data;
}

export async function search({ pattern, parent = "faqs" }) {
  const { data } = await axios.get(
    `/knowledge/pages/?search=${pattern}&parent=${parent}`
  );
  return data;
}
