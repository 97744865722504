import { Box, Typography } from "@mui/material";
import { LowerDialogRight } from "components/icons/Dialogs";
import { useTranslation } from "react-i18next";

export const DialogSuccesForm = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Rock Salt",
          fontSize: "19px",
          fontWeight: "400",
          lineHeight: "18px",
          letterSpacing: "-0.26px",
          textAlign: "center",
          position: "absolute",
          zIndex: 1,
          top: "92px",
          left: "46px",
        }}
      >
        <span>{t("succes_form.great_day_message_one")}</span>
        <br />
        <span>{t("succes_form.great_day_message_two")}</span>
        <br />
        <span>{t("succes_form.great_day_message_three")}</span>
      </Typography>
      <LowerDialogRight />
    </Box>
  );
};
