import { useStore } from "hooks/useStore";
import { PacksTariffPicker } from "../components/packs/PacksTariffPicker";

export const PackTariffs = ({ packs, ...props }) => {
  const { setLines, setIsSharedLines, selectedPackCode, setSelectedPackCode } =
    useStore();
  function onChange(matchedPack) {
    if (matchedPack) {
      setIsSharedLines(matchedPack.category === "mobile_shared_data");
      setSelectedPackCode(matchedPack.code);
      setLines(matchedPack.products);
    } else {
      setSelectedPackCode(undefined);
      setLines([]);
    }
  }
  return (
    <PacksTariffPicker
      initialPackCode={selectedPackCode}
      packs={packs}
      onChange={onChange}
      {...props}
    />
  );
};
