import { createTheme } from "@mui/material/styles";
/**
 * TODO
 * components -> MuiCssBaseline -> font-face: work sans
 */
export const rawTheme = {
  typography: {
    fontFamily: '"Work Sans"',
    h0: {
      fontWeight: "800",
      lineHeight: "6.5rem",
      fontSize: "6rem",
      letterSpacing: "-0.12rem",
    },
    body_xl: {
      fontSize: "1.5rem",
      fontWeight: "400",
      lineHeight: "2rem",
    },
    body_xl_bold: {
      fontSize: "1.5rem",
      fontWeight: "600",
      lineHeight: "2rem",
    },
    body_large: {
      fontSize: "1.25rem",
      fontWeight: "400",
      lineHeight: "1.75rem",
    },
    body_large_bold: {
      fontSize: "1.25rem",
      fontWeight: "600",
      lineHeight: "1.75rem",
    },
    body: {
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5rem",
    },
    body_bold: {
      fontSize: "1rem",
      fontWeight: "600",
      lineHeight: "1.5rem",
    },
    body_small: {
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "1.25rem",
    },
    body_small_bold: {
      fontSize: "0.875rem",
      fontWeight: "600",
      lineHeight: "1.25rem",
    },
    link_button: {
      fontSize: "1rem",
      fontWeight: "600",
      lineHeight: "1.5rem",
      cursor: "pointer",
    },
    link_button_small: {
      fontSize: "0.875rem",
      fontWeight: "600",
      lineHeight: "1.25rem",
      cursor: "pointer",
    },
    link_xl_bold: {
      fontSize: "1.5rem",
      fontWeight: "600",
      lineHeight: "2rem",
      textDecorationLine: "underline",
      cursor: "pointer",
    },
    link_large: {
      fontSize: "1.25rem",
      fontWeight: "600",
      lineHeight: "1.75rem",
      textDecorationLine: "underline",
      cursor: "pointer",
    },
    link: {
      fontSize: "1rem",
      fontWeight: "600",
      lineHeight: "1.5rem",
      textDecorationLine: "underline",
      cursor: "pointer",
    },
    link_small: {
      fontSize: "0.875rem",
      fontWeight: "600",
      lineHeight: "1.25rem",
      textDecorationLine: "underline",
      cursor: "pointer",
    },
    link_hairline: {
      fontSize: "1rem",
      fontWeight: "600",
      lineHeight: "1.25rem",
      letterSpacing: "0.1rem",
      cursor: "pointer",
    },
    link_hairline_small: {
      fontSize: "0.875rem",
      fontWeight: "600",
      lineHeight: "1.25rem",
      letterSpacing: "0.075rem",
      cursor: "pointer",
    },
  },
  spacing: [0, 8, 10, 15, 20, 25, 30, 40, 50, 60, 75, 100, 125],
  palette: {
    primary: {
      light: "#d74381",
      main: "#CE1462",
      dark: "#900e44",
    },
    secondary: {
      light: "#ffc965",
      main: "#FFBC3F",
      dark: "#b2832c",
    },
    error: {
      light: "#ff7765",
      main: "#FF563F",
      dark: "#b23c2c",
    },
    sand: {
      light: "#dcc8a8",
      main: "#D4BB93",
      dark: "#948266",
      contrastText: "#000",
    },
    green: {
      light: "#83bb6f",
      main: "#65AA4B",
      dark: "#467634",
      contrastText: "#000",
    },
    grey: {
      50: "#FFF",
      100: "#F1F1F1",
      200: "#D9D9D9",
      400: "#B9B9B9",
      500: "#999",
      700: "#00000080",
      800: "#4A4A4A",
      900: "#000",
    },
    text: {
      primary: "#000",
      main: "#3e3382",
    },
    info: {
      main: "#b1a76e",
      dark: "#8c8248",
    },
    background: {
      darkest: "#808faa",
      dark: "#ced2d9",
      main: "#e8e8e8",
      light: "#f2f2f2",
    },
  },
  shadows: [
    "none",
    "4px 4px 0px 0px rgba(0,0,0)",
    "8px 8px 0px 0px rgba(0,0,0)",
    "16px 16px 0px 0px rgba(0,0,0)",
  ].concat(Array(21).fill("none")),
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: "800",
          lineHeight: "4.5rem",
          fontSize: "4rem",
          letterSpacing: "-0.08rem",
        },
        h2: {
          fontWeight: "800",
          lineHeight: "3.5rem",
          fontSize: "3rem",
          letterSpacing: "-0.06rem",
        },
        h3: {
          fontWeight: "800",
          lineHeight: "2.5rem",
          fontSize: "2rem",
          letterSpacing: "-0.04rem",
        },
        h4: {
          fontWeight: "800",
          lineHeight: "2rem",
          fontSize: "1.5rem",
          letterSpacing: "-0.03rem",
        },
        gutterBottom: {
          marginBottom: "0.5rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "padding": 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          "border": "1px solid #000",
          "color": "#000",
          "&:hover": { background: "none", border: "1px solid #000" },
        },
      },
      variants: [
        {
          props: { variant: "primary" },
          style: {
            "background": "#000",
            "color": "#F1F1F1",
            "&:hover": { background: "#000" },
            "&:disabled": { background: "#B9B9B9", color: "#D9D9D9" },
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            "background": "#D9D9D9",
            "color": "#000",
            "&:hover": { background: "#D9D9D9" },
          },
        },
        {
          props: { variant: "transparent" },
          style: {
            "color": "#000",
            "textDecoration": "underline",
            "&:hover": { background: "none", textDecoration: "underline" },
          },
        },
        {
          props: { size: "normal" },
          style: {
            padding: "16px",
            fontSize: "1rem",
            fontWeight: "600",
            lineHeight: "1.5rem",
          },
        },
        {
          props: { size: "compact" },
          style: {
            padding: "8px 16px",
            fontSize: "0.875rem",
            fontWeight: "600",
            lineHeight: "1.25rem",
          },
        },
        {
          props: { size: "small" },
          style: {
            fontWeight: "600",
          },
        },
      ],
    },
  },
};
export const theme = createTheme(rawTheme);
