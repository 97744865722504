import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CheckListCard } from "components/CheckListCard";
import { theme } from "theme";
import { Button } from "components/Button";
import { getUrlQuery, openUrl } from "lib/helpers/urls";
import { Separator } from "components/Separator";

export const TariffCard = ({
  icon,
  title,
  checkLiterals,
  buttonVariant,
  to,
  queryParams,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ position: "relative", top: "30px" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>{icon}</Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 6,
          padding: `65px ${isMobile ? "50px" : "60px"} 30px`,
          borderRadius: "16px",
          border: `3px solid ${theme.palette.grey[900]}`,
          background: theme.palette.grey[100],
          height: isMobile ? "550px" : "430px",
          width: isMobile ? "300px" : "360px",
        })}
      >
        <Typography variant="h4">{t(title)}</Typography>
        <Separator />
        <CheckListCard literals={checkLiterals.map((lit) => t(lit))} />
        <Button
          sx={{ mt: "auto" }}
          variant={buttonVariant}
          fullWidth
          onClick={() => openUrl(getUrlQuery(to, queryParams), "_parent")}
        >
          {t("funnel.join.common.button")}
        </Button>
      </Box>
    </Box>
  );
};
