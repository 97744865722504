import { Carousel } from "components/layouts/Carousel";
import { PricingCard } from "./PricingCard";
import { Box, useMediaQuery } from "@mui/material";
import { theme } from "theme";

export const RecommendedTariffsPicker = ({ recommendedPacks, onClick }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const content = recommendedPacks.map((recommendedPack, index) => (
    <PricingCard
      key={recommendedPack.code}
      buttonVariant={index % 2 === 0 ? "outlined" : "primary"}
      cardBackground={index % 2 === 0 ? "grey.100" : "grey.50"}
      mobiles={recommendedPack.mobiles_in_pack}
      price={recommendedPack.price}
      fiber_bandwidth={recommendedPack.fiber_bandwidth}
      fiber_description={
        recommendedPack.products.find(({ category }) => category === "fiber")
          .name
      }
      mobile_data={
        recommendedPack.products.find(({ category }) => category === "mobile")
          .data
      }
      category={recommendedPack.category}
      onContract={() => onClick(recommendedPack)}
    />
  ));
  return (
    <Box sx={{ bgcolor: "grey.100", height: "100%" }}>
      {isMobile ? (
        <Carousel slides={content} />
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            alignItems: "baseline",
            p: "60px",
          }}
        >
          {content}
        </Box>
      )}
    </Box>
  );
};
