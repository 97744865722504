import { Condition } from "./Condition";
import {
  required,
  mustNotBe,
  requiredLastName,
  composeValidators,
  matchVatFormat,
} from "lib/form/validators";
import { useTranslation } from "react-i18next";
import { useApplicationContext } from "hooks/useApplicationContext";
import { useStore } from "hooks/useStore";
import { CheckField } from "components/fields/CheckField";
import { InputField } from "components/fields/InputField";
import { Box, useMediaQuery } from "@mui/material";

export const PreviousOwnerFields = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const formStepDataByKey = useStore((state) => state.formStepDataByKey);
  const { currentUser } = useApplicationContext();

  const currentUserVat =
    formStepDataByKey["partner/personal-data"]?.vat || currentUser.username;

  const disallowedVatValues = [
    currentUserVat,
    currentUserVat.replace(/^es/, ""),
  ];

  return (
    <>
      <Box mt={isMobile ? -3 : 0} mb={isMobile ? 1 : 0} width={"100%"}>
        <CheckField.FormField
          initialValue={false}
          name="other_person"
          label={t(
            "funnel.signup.data.steps.mobile_line_additional_data.other_person"
          )}
        />
      </Box>
      <Condition when="other_person" is={true}>
        {!isMobile && <Box />}
        <InputField.FormField
          validate={composeValidators(
            mustNotBe({
              values: disallowedVatValues,
              errorKey: "vat_number_in_use",
            }),
            matchVatFormat,
            required
          )}
          name="previous_owner_vat"
          label={t(
            "funnel.signup.data.steps.mobile_line_additional_data.previous_owner_vat"
          )}
        />
        <InputField.FormField
          validate={required}
          name="previous_owner_name"
          label={t(
            "funnel.signup.data.steps.mobile_line_additional_data.previous_owner_name"
          )}
        />
        <InputField.FormField
          validate={required}
          name="previous_owner_surname"
          label={t(
            "funnel.signup.data.steps.mobile_line_additional_data.previous_owner_surname"
          )}
        />
        <InputField.FormField
          validate={requiredLastName}
          name="previous_owner_lastname"
          label={t(
            "funnel.signup.data.steps.mobile_line_additional_data.previous_owner_lastname"
          )}
        />
      </Condition>
    </>
  );
};
