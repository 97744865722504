import { TopBar } from "components/TopBar";
import { Trans, useTranslation } from "react-i18next";
import { useQueryParams } from "hooks/useQueryParams";
import { useCoopAgreement } from "hooks/queries/useCoopAgreement";
import { FullScreenCenteredLayout } from "components/layouts/FullScreenCenteredLayout.js";
import { Tiles } from "components/layouts/Tiles";
import { Box, useMediaQuery } from "@mui/material";
import { Link } from "components/Link";
import { Spinner } from "components/Spinner";
import { Heading } from "./components/Heading.js";
import { TariffCard } from "./components/TariffCard.js";
import {
  Mobile,
  MobileAssociatedFiber,
  Router,
} from "components/icons/Products.js";
import { Carousel } from "components/layouts/Carousel.js";
import { useApplicationContext } from "hooks/useApplicationContext.js";
import { Member } from "./components/Member.js";
const Container = ({ children }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        p: `60px ${isMobile ? "24px" : "96px"}`,
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
};
const Content = ({ exclusiveTariffs = false }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { currentUser } = useApplicationContext();
  const {
    opting_for_role: optingForRole,
    locale,
    coop_agreement_code: coopAgreementCode,
  } = useQueryParams();
  const queryParams = {
    opting_for_role: optingForRole,
    locale,
    coop_agreement_code: coopAgreementCode,
  };
  const searchCoopAgreement =
    optingForRole === "coop_agreement" && coopAgreementCode !== "SC";
  const showMember =
    !exclusiveTariffs && !Boolean(currentUser) && !Boolean(optingForRole);

  const {
    data: { name: coopAgreement, first_month_promotion: isPromotionActive } = {
      name: null,
    },
    isLoading,
  } = useCoopAgreement({
    automatically_run: searchCoopAgreement,
    coopAgreementCode,
  });

  if (isLoading) {
    return (
      <FullScreenCenteredLayout>
        <Spinner />
      </FullScreenCenteredLayout>
    );
  }

  if (searchCoopAgreement && !coopAgreement) {
    return (
      <FullScreenCenteredLayout>
        <Tiles columns={1}>
          <Box mb={2}>
            <Trans i18nKey="funnel.join.coop_agreement.error">
              <Link
                target="_blank"
                to={"mailto:" + t("common.assistance_email")}
              />
            </Trans>
          </Box>
        </Tiles>
      </FullScreenCenteredLayout>
    );
  }

  const tariffCards = [
    <TariffCard
      icon={<Mobile />}
      title={"funnel.join.common.mobile"}
      checkLiterals={[
        "funnel.join.common.checks.mobile.first",
        "funnel.join.common.checks.mobile.second",
        "funnel.join.common.checks.mobile.third",
      ]}
      buttonVariant={"outlined"}
      to={"/tariffs/mobile"}
      queryParams={queryParams}
    />,
    <TariffCard
      icon={<MobileAssociatedFiber />}
      title={"funnel.join.common.both"}
      checkLiterals={[
        "funnel.join.common.checks.both.first",
        "funnel.join.common.checks.both.second",
        "funnel.join.common.checks.both.third",
      ]}
      buttonVariant={"primary"}
      to={"/tariffs/internet_and_mobile"}
      queryParams={queryParams}
    />,
    <TariffCard
      icon={<Router />}
      title={"funnel.join.common.internet"}
      checkLiterals={[
        "funnel.join.common.checks.internet.first",
        "funnel.join.common.checks.internet.second",
        "funnel.join.common.checks.internet.third",
      ]}
      buttonVariant={"outlined"}
      to={"/tariffs/internet"}
      queryParams={queryParams}
    />,
  ];
  return (
    <Container>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Heading
          simpleHeader={exclusiveTariffs}
          optingForRole={optingForRole}
          coopAgreementCode={coopAgreementCode}
          coopAgreement={coopAgreement}
          isPromotionActive={isPromotionActive}
        />
      </Box>
      {isMobile ? (
        <Box sx={{ mx: "-16px" }}>
          <Carousel slides={tariffCards} />
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", gap: 5 }}>
          {tariffCards.map((tariffCard, index) => (
            <div key={`card_${index}`}>{tariffCard}</div>
          ))}
        </Box>
      )}
      {showMember && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Member />
        </Box>
      )}
    </Container>
  );
};
export const Join = ({ ...props }) => {
  return (
    <TopBar>
      <Content {...props} />
    </TopBar>
  );
};
