import { Box, Typography, useMediaQuery } from "@mui/material";
import { Button } from "components/Button";
import { Spinner } from "components/Spinner";
import { Tiles } from "components/layouts/Tiles";
import { AcceptTerms } from "components/shared/AcceptTerms";
import { SignUpCard } from "components/shared/SignUpCard";
import { useApplicationContext } from "hooks/useApplicationContext";
import { useDerivedState } from "hooks/useDerivedState";
import { useLinesFormDerivedState } from "hooks/useLinesFormDerivedState";
import { useStore } from "hooks/useStore";
import { postForm } from "lib/api/submitForms";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ConfirmationSummary } from "./shared/ConfirmationSummary";
import { LineStepGroup } from "./shared/StepGroups/LineStepGroup";
import { PaymentStepGroup } from "./shared/StepGroups/PaymentStepGroup";
import { PersonalDataStepGroup } from "./shared/StepGroups/PersonalDataStepGroup";
import { formToApi } from "./shared/formToApi";
import { getLineGroupProps } from "./shared/getLineGroupProps";
import { SuperCard } from "components/SuperCard";

const waitFor = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const Content = ({ tariffs }) => {
  const { currentUser } = useApplicationContext();
  const loggedIn = Boolean(currentUser);
  const lines = useStore((state) => state.lines);
  const isSharedLines = useStore((state) => state.isSharedLines);
  const optingForRole = useStore((state) => state.optingForRole);
  const { t } = useTranslation();
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const history = useHistory();
  const { mustPayMonthlyBill, showPayMemberFee } = useDerivedState();
  const state = useStore((state) => state);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { getLinesFromState, internetLineInFormData } =
    useLinesFormDerivedState();
  const [isLoading, setIsLoading] = useState(null);

  const gotoThanks = () => {
    history.push("thanks");
  };

  const onSubmit = async () => {
    const payload = formToApi(
      state,
      getLinesFromState,
      Boolean(internetLineInFormData),
      {
        loggedIn,
        mustPayMonthlyBill,
        showPayMemberFee,
      }
    );

    try {
      setIsLoading(true);
      await postForm(payload);
      await waitFor(250);

      gotoThanks();
    } catch (e) {
      console.error("Error submiting form!");
      console.error(e);
      gotoThanks();
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ height: "67vh", display: "flex" }}>
        <Spinner />
      </Box>
    );
  }

  const onClickFooter = () =>
    history.push(
      `/signup/data?intent=${encodeURIComponent(
        JSON.stringify({ optingForRole, isNewSignupFlow: false })
      )}`
    );

  return (
    <Tiles columns={1} spacing={4}>
      {!loggedIn ? (
        <PersonalDataStepGroup optingForRole={optingForRole} confirmMode />
      ) : (
        <SignUpCard
          confirmMode
          index={1}
          title={t("funnel.signup.data.steps.personal_data.title")}
        >
          <Box mt={3}>
            <ConfirmationSummary
              i18nPrefix="funnel.signup.data.steps.personal_data.confirmation"
              fields={{
                fullname: currentUser.full_name,
                email: currentUser.email,
              }}
            />
          </Box>
        </SignUpCard>
      )}
      {lines.map((line, index) => {
        return (
          <LineStepGroup
            confirmMode
            line={line}
            stepsBefore={2}
            tariffs={tariffs}
            index={index}
            isLast={false}
            {...getLineGroupProps(index, lines)}
          />
        );
      })}
      <PaymentStepGroup confirmMode />
      <SuperCard
        sx={{ p: 5, width: isMobile ? "100%" : "540px", justifySelf: "center" }}
      >
        <Tiles columns="1" spacing={5}>
          {!isSharedLines && lines.length > 0 ? (
            <Typography variant="link_small" onClick={() => onClickFooter()}>
              {t("funnel.signup.confirm.reselect_tariffs")}
            </Typography>
          ) : (
            <div />
          )}
          <AcceptTerms setIsAccepted={setHasAcceptedTerms} />
          <Button
            disabled={!hasAcceptedTerms}
            type="submit"
            onClick={onSubmit}
            sx={{ mt: 3 }}
          >
            {t("common.finish")}
          </Button>
        </Tiles>
      </SuperCard>

      <Box height="150px" />
    </Tiles>
  );
};

export const Confirm = ({ tariffs }) => <Content tariffs={tariffs} />;
