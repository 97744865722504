import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Typography } from "@mui/material";
import { Form } from "components/Form";
import { Tiles, TileSpan } from "components/layouts/Tiles";
import { useDerivedState } from "hooks/useDerivedState";
import { useStore } from "hooks/useStore";
import { required } from "lib/form/validators";
import { formatIBAN } from "lib/helpers/format";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AddressPicker } from "../AddressPicker";
import { ConfirmationSummary } from "../ConfirmationSummary";
import { formatFullAddress } from "../formatFullAddress";
import { FormStep } from "../FormStep";
import { FormStepGroup } from "../FormStepGroup";
import { PaymentMethodPicker } from "../PaymentMethodPicker.js";
import { Associate } from "./AssociateGroup";
import { PaymentMemberFeeGroup } from "./PaymentMemberFeeGroup";
import { useApplicationContext } from "hooks/useApplicationContext";

export const PaymentStepGroup = ({ confirmMode }) => {
  const saveAddress = useStore((state) => state.saveAddress);
  const setOptingRole = useStore((state) => state.setOptingRole);
  const setCoopAgreementCode = useStore((state) => state.setCoopAgreementCode);
  const lines = useStore((state) => state.lines);
  const { currentUser } = useApplicationContext();
  const loggedIn = Boolean(currentUser);
  const { t } = useTranslation();
  const history = useHistory();

  const optingRoleResolver = (select_role) => {
    if (select_role === "member") {
      setOptingRole("new_member");
      setCoopAgreementCode("");
      return;
    }
    setOptingRole("coop_agreement");
    setCoopAgreementCode("SC");
  };

  const {
    showPayMemberFee,
    mustPayMonthlyBill,
    mustShipmentSimCard,
    mustSelectRole,
    getAddressById,
  } = useDerivedState();

  const gotoConfirmStep = () => {
    history.push("confirm");
  };

  const getPaymentFeeSumamry = ({ iban, address }) =>
    [iban && formatIBAN(iban), formatFullAddress(address)].join(" · ");

  const getPaymentFeeConfirmationFields = ({ iban, pays_in_ten_terms }) => ({
    service_iban: iban && formatIBAN(iban),
    pays_in_ten_terms: t(`common.${pays_in_ten_terms ? "yes" : "no"}`),
  });

  const getLastIndex = () => lines.length + (loggedIn ? 0 : 1);

  return (
    <FormStepGroup
      index={getLastIndex() + 1}
      title={t(
        mustShipmentSimCard
          ? "funnel.signup.data.steps.payment_shipment_step_group.title"
          : "funnel.signup.data.steps.payment_step_group.title"
      )}
      confirmMode={confirmMode}
    >
      {mustPayMonthlyBill && (
        <FormStep
          id="payment/monthly-bill"
          title={t("funnel.signup.data.steps.payment_monthly_bill.title")}
          renderSummary={(values) => {
            if (!confirmMode) {
              return (
                <Typography variant="body_small" color="grey.800">
                  {formatIBAN(values.service_iban)}
                </Typography>
              );
            }
            return (
              <ConfirmationSummary
                i18nPrefix="funnel.signup.data.steps.payment_monthly_bill.confirmation"
                fields={{
                  iban: values.service_iban && formatIBAN(values.service_iban),
                  address: formatFullAddress(
                    getAddressById(values?.address?._id)
                  ),
                }}
              />
            );
          }}
          onSubmit={(values) => {
            saveAddress(values.address);

            if (mustShipmentSimCard) {
              return;
            } else if (showPayMemberFee || mustSelectRole) {
              return;
            }

            gotoConfirmStep();
          }}
        >
          <Tiles columns={2} spacing={4}>
            <TileSpan span={2}>
              <PaymentMethodPicker
                name="service_iban"
                label={t("funnel.signup.data.steps.payment_monthly_bill.iban")}
              />
            </TileSpan>
            <TileSpan span={2}>
              <AddressPicker
                name="address"
                label={t("funnel.signup.data.steps.payment_data.address")}
                validate={required}
              />
            </TileSpan>
            <Box maxWidth={106}>
              <Form.Submit endIcon={<ArrowForwardIcon />}>
                {t("common.continue")}
              </Form.Submit>
            </Box>
          </Tiles>
        </FormStep>
      )}
      {mustShipmentSimCard && (
        <FormStep
          id="payment/shipment"
          title={t("funnel.signup.data.steps.shipment.title")}
          renderSummary={(values) => {
            if (!confirmMode) {
              return (
                <Typography variant="body_small" color="grey.800">
                  {formatFullAddress(
                    getAddressById(values?.delivery_address?._id)
                  )}
                </Typography>
              );
            }
            return (
              <ConfirmationSummary
                i18nPrefix="funnel.signup.data.steps.shipment.confirmation"
                fields={{
                  delivery_address: values?.delivery_address?._id
                    ? formatFullAddress(
                        getAddressById(values?.delivery_address?._id)
                      )
                    : null,
                }}
              />
            );
          }}
          onSubmit={(values) => {
            saveAddress(values.delivery_address);

            if (showPayMemberFee || mustSelectRole) {
              return;
            }

            gotoConfirmStep();
          }}
        >
          <Tiles columns={1} spacing={4}>
            <TileSpan span="all">
              <AddressPicker
                validate={required}
                name="delivery_address"
                label={t(
                  "funnel.signup.data.steps.mobile_line_additional_data.delivery_address"
                )}
              />
            </TileSpan>
            <Box maxWidth={106}>
              <Form.Submit endIcon={<ArrowForwardIcon />}>
                {t("common.continue")}
              </Form.Submit>
            </Box>
          </Tiles>
        </FormStep>
      )}
      {showPayMemberFee && (
        <FormStep
          id="payment/member-fee"
          title={t("funnel.signup.data.steps.payment_data.title")}
          initialValues={{
            pays_in_ten_terms: false,
            use_same_bank_account: true,
          }}
          onSubmit={(values) => {
            saveAddress(values.address);
            gotoConfirmStep();
          }}
          renderSummary={(values) => {
            if (!confirmMode) {
              return (
                <Typography variant="body_small" color="grey.800">
                  {getPaymentFeeSumamry(values)}
                </Typography>
              );
            }

            return (
              <ConfirmationSummary
                i18nPrefix="funnel.signup.data.steps.payment_data.confirmation"
                fields={getPaymentFeeConfirmationFields(values)}
              />
            );
          }}
        >
          <PaymentMemberFeeGroup />
        </FormStep>
      )}
      {mustSelectRole && (
        <FormStep
          id="payment/select-role"
          title={t("funnel.signup.data.steps.select_role.title")}
          initialValues={{
            select_role: "coop_agreement",
            use_same_bank_account: true,
          }}
          onSubmit={({ select_role, address }) => {
            optingRoleResolver(select_role);
            saveAddress(address);
            gotoConfirmStep();
          }}
          renderSummary={(values) => {
            const hireAsMember = values.select_role === "member";
            if (!confirmMode) {
              <Typography variant="body_small" color="grey.800">
                {hireAsMember
                  ? getPaymentFeeSumamry(values)
                  : t("funnel.tariffs.step_confirm_modal.second_step.as_user")}
              </Typography>;
            }
            return (
              <ConfirmationSummary
                i18nPrefix={
                  hireAsMember
                    ? "funnel.signup.data.steps.payment_data.confirmation"
                    : "funnel.signup.data.steps.select_role.confirmation"
                }
                fields={
                  hireAsMember
                    ? {
                        contract_as: t(
                          "funnel.tariffs.step_confirm_modal.second_step.as_partner"
                        ),
                        ...getPaymentFeeConfirmationFields(values),
                      }
                    : {
                        contract_as: t(
                          "funnel.tariffs.step_confirm_modal.second_step.as_user"
                        ),
                      }
                }
              />
            );
          }}
        >
          <Associate />
        </FormStep>
      )}
    </FormStepGroup>
  );
};
