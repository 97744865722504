import { Box, Typography, useMediaQuery } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

export const Alert = ({ sx, ...props }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.sand.main,
        height: isMobile ? "80px" : "40px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        padding: "1rem",
        marginTop: "10px",
        ...sx,
      })}
    >
      <HelpIcon sx={{ marginRight: "1rem" }} />
      <Typography variant="body_small" sx={{ textTransform: "uppercase" }}>
        {props.text}
      </Typography>
    </Box>
  );
};
