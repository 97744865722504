import React from "react";
import { PaymentStepGroup } from "./shared/StepGroups/PaymentStepGroup";
import { Box } from "@mui/material";
import { Tiles } from "components/layouts/Tiles";

export const Payment = () => {
  return (
    <Tiles columns={1} spacing={5}>
      <PaymentStepGroup />
      {/* TODO some padding at the bottom to avoid scroll jumping when collapsing boxes */}
      <Box height="225px" />
    </Tiles>
  );
};
