import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Link } from "components/Link";
import { CheckField } from "components/fields/CheckField";
import { InputField } from "components/fields/InputField";
import { SelectField } from "components/fields/SelectField";
import { Form } from "components/Form";
import { Alert } from "components/Alert";
import { Image } from "components/layouts/Image";
import { Tiles } from "components/layouts/Tiles";
import { Modal } from "components/layouts/Modal";
import { postWeCallYou } from "lib/api/tickets";
import { composeValidators, required, validPhone } from "lib/form/validators";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import FormSucces from "components/formSucces/FormSucces";

function getReason(origin) {
  switch (origin) {
    case "tariffs":
      return "pagina_tarifes";
    case "help":
      return "ajuda";
    case "change_request":
      return "canvis";
    case "contract_info":
      return "contractar_info";
    case "info_coop":
      return "info_coop";
    case "more_service":
      return "mes_serveis";
    default:
      return "altres";
  }
}

export const ModalWeCallYou = ({ origin, ...props }) => {
  const { t, i18n } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    phone: "",
    schedule: "",
  });
  const privacyUrl = t("urls.privacy");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const ScheduleOptions = [
    { value: "1012", label: "10h-12h" },
    { value: "1214", label: "12h-14h" },
    { value: "1416", label: "14h-16h" },
    { value: "1618", label: "16h-18h" },
  ];
  const reasonOptions = [
    {
      label: t("help_we_call_you.modal.reason.tariffs"),
      value: "pagina_tarifes",
    },
    {
      label: t("help_we_call_you.modal.reason.help"),
      value: "ajuda",
    },
    {
      label: t("help_we_call_you.modal.reason.change_request"),
      value: "canvis",
    },
    {
      label: t("help_we_call_you.modal.reason.contract_info"),
      value: "contractar_info",
    },
    {
      label: t("help_we_call_you.modal.reason.info_coop"),
      value: "info_coop",
    },
    {
      label: t("help_we_call_you.modal.reason.more_service"),
      value: "mes_serveis",
    },
  ];
  const onFormSubmit = async (values) => {
    await postWeCallYou({
      ...values,
      reason: getReason(origin),
      lang: `${i18n.language}_ES`,
    });
    setUserData({
      name: values.name,
      phone: values.phone,
      schedule: values.schedule,
    });

    setIsSubmitted(true);
  };
  // Review extra space in the bottom
  return (
    <Modal title={t("help_we_call_you.modal.title")} {...props}>
      {isSubmitted ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            overflow: "hidden",
            height: "100%",
          }}
        >
          <FormSucces
            name={userData.name}
            phone={userData.phone}
            schedule={userData.schedule}
            {...props}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <>
            <Box // Review this container when set src image
              sx={{
                width: "100%",
                height: isMobile ? "212px" : "48px",
                backgroundColor: "grey.100",
              }}
            >
              <Image
                alt="We call you"
                src="" // TODO set image
              />
            </Box>
            <Box
              sx={{
                zIndex: "499",
                bottom: "40px",
                background: "white",
                mx: "36px",
                pt: "36px",
                pb: "16px",
                px: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                flex: "1",
              }}
            >
              <Typography variant="h3" gutterBottom>
                {t("help_we_call_you.modal.help_you")}
              </Typography>
              <Typography variant="body" gutterBottom>
                {t("help_we_call_you.modal.description")}
              </Typography>
              <Alert text={t("help_we_call_you.modal.alert")} />
              <Box sx={{ mt: 6 }}>
                <Form id={"change-owner"} onSubmit={onFormSubmit}>
                  <Tiles columns={1} spacing={5}>
                    <InputField.FormField
                      id="name"
                      name="name"
                      label={t("funnel.signup.data.steps.personal_data.name")}
                      validate={required}
                      fullWidth
                    />
                    {!origin && (
                      <SelectField.FormField
                        id="reason"
                        name="reason"
                        label={t("help_we_call_you.modal.reason.title")}
                        options={reasonOptions}
                        validate={required}
                        fullWidth
                      />
                    )}
                    <Tiles columns={2} spacing={5}>
                      <InputField.FormField
                        id="phone"
                        name="phone"
                        label={t(
                          "funnel.signup.data.steps.personal_data.phone"
                        )}
                        mask="000 00 00 00"
                        validate={composeValidators(required, validPhone)}
                      />
                      <SelectField.FormField
                        id="schedule"
                        name="schedule"
                        label={t("help_we_call_you.modal.schedule")}
                        infoLabel={t("help_we_call_you.modal.schedule_info")}
                        options={ScheduleOptions}
                        validate={required}
                      />
                    </Tiles>
                    <CheckField
                      label={t("help_we_call_you.modal.authorization")}
                      onChange={(event) =>
                        setIsButtonDisabled(!event.target.checked)
                      }
                      infoLabel={
                        <Trans i18nKey="help_we_call_you.modal.authorization_info">
                          <Link target="_blank" to={privacyUrl} />
                        </Trans>
                      }
                    />
                  </Tiles>
                  <Box mt={"16px"}></Box>
                  <Form.Submit
                    fullWidth
                    endIcon={<ArrowForwardIcon />}
                    disabled={isButtonDisabled}
                    sx={{
                      textTransform: "none",
                    }}
                  >
                    {t("help_we_call_you.modal.call_me")}
                  </Form.Submit>
                </Form>
              </Box>
            </Box>
          </>
        </Box>
      )}
    </Modal>
  );
};
