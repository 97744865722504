import { Box, Typography, useMediaQuery } from "@mui/material";
import { SuperCard } from "components/SuperCard";
import { WLandlineChip, WOPermanenceChip } from "components/Chips";
import { LargeArrowCurve } from "components/icons/LargeArrowCurve";
import { DialogHelp } from "components/dialogs/DialogHelp";
import { FloatingCard } from "components/layouts/FloatingCard";
import { useTranslation } from "react-i18next";
import { formatBandwith } from "lib/helpers/format";
import { Price } from "../PricePreview";
import { Divider } from "components/Divider";
import { TariffInfo } from "../TariffInfo";

export const InternetTariffInfo = ({
  code,
  has_landline_phone,
  category,
  bandwidth,
  data,
  price,
  isInfoArrowed = true,
  isInfoInCard = true,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const Content = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="body_xl_bold">
          {t(
            `funnel.tariffs.internet.contract_info.title_${category}`,
            category === "fiber"
              ? {
                  bandwidth: formatBandwith(bandwidth),
                }
              : { data: formatBandwith(data) }
          )}
        </Typography>
        {isInfoInCard && <TariffInfo tariffCode={code} />}
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", gap: "4px" }}>
        <WOPermanenceChip />
        {has_landline_phone && <WLandlineChip />}
      </Box>
      <Typography variant="body_small">
        {t(`funnel.tariffs.internet.contract_info.info.${code}`)}
      </Typography>
      <Divider flexItem />
      <Price price={parseFloat(price).toFixed(2)} stackingPrice={true} />
    </Box>
  );
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {isInfoInCard ? (
        <FloatingCard sx={{ mt: 1 }}>
          <SuperCard sx={{ mt: 1, width: isMobile ? "100%" : "540px" }}>
            <Content />
          </SuperCard>
        </FloatingCard>
      ) : (
        <Content />
      )}
      {isInfoArrowed && (
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              position: "relative",
              right: isMobile ? "10%" : "40%",
              width: 100,
            }}
          >
            <LargeArrowCurve variant="dashed" />
          </Box>
          <Box
            sx={{
              position: "relative",
              left: isMobile ? "5%" : "35%",
              bottom: "10px",
              width: 200,
            }}
          >
            <DialogHelp origin="tariffs" />
          </Box>
        </Box>
      )}
    </Box>
  );
};
