import { Box, Typography } from "@mui/material";
import { Button } from "./Button";
import { useTranslation } from "react-i18next";

export const RequestError = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        textAlign: "center",
      }}
    >
      <Typography variant="body">
        {t("common.errors.request_failed")}
      </Typography>
      {onClick && <Button onClick={onClick}>{t("common.retry")}</Button>}
    </Box>
  );
};
