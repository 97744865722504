export const BigCloud = ({
  width = "136",
  height = "48",
  minX = 0,
  minY = 0,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`${minX} ${minY} 136 48`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.12235 39.0597C-2.25295 33.9174 7.2143 33.136 12.4948 33.3881C13.1863 31.7496 15.814 28.0945 20.7928 26.5821C25.7716 25.0696 29.5308 26.5821 30.7881 27.5274C30.1594 25.6368 29.8828 20.4567 33.8055 14.8607C37.7282 9.26466 45.121 9.50414 48.327 10.3234C50.4015 6.92039 57.6056 0.303464 69.8263 1.05968C82.047 1.8159 84.3478 13.3482 83.9706 19.0199C84.2849 18.7048 86.1205 18.4527 90.9484 19.9652C95.7763 21.4776 96.229 26.267 95.8518 28.4726C96.7947 27.9685 99.699 26.8846 103.773 26.5821C107.846 26.2796 110.625 29.9851 111.505 31.8756C120.683 31.7496 138.134 32.9721 134.513 38.8706C130.892 44.7692 107.23 45.1095 95.8518 44.5423C90.9484 45.3615 79.2181 47 71.5236 47C63.8291 47 49.5843 45.3615 43.4236 44.5423C31.4796 44.8574 6.49765 44.202 2.12235 39.0597Z"
      fill="white"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const SmallCloud = ({
  width = "61",
  height = "33",
  minX = 0,
  minY = 0,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`${minX} ${minY} ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.39089 16.7469C0.698255 19.2157 0.931795 21.6158 2.04618 23.9486C5.22075 30.7875 14.1161 28.9883 18.1669 27.2339C19.3033 28.6414 23.4996 31.5585 31.1943 31.9663C38.889 32.3741 50.8476 28.9813 55.865 27.2339C57.4067 26.6104 58.7938 25.5458 59.5347 23.9691C60.1361 22.6892 60.1343 21.8406 59.646 20.5398C58.8668 18.4637 57.8355 17.2118 55.6816 16.6029C53.8044 16.0723 51.4013 15.9979 49.5349 16.6852C48.6729 17.0027 48.5695 17.2658 49.0566 16.2462C49.8219 14.6441 51.278 13.5789 51.9661 11.891C52.6066 10.3197 52.3939 8.40267 51.7433 6.87039C50.8823 4.8428 49.6841 3.07674 47.6674 2.17904C45.6608 1.28583 43.6225 1.57739 41.678 2.4671C40.3469 3.07616 39.3752 4.01209 38.8734 5.43006C38.8109 5.60683 38.7235 5.29091 38.6965 5.25174C38.3632 4.76878 37.8369 4.39687 37.458 3.94858C36.5477 2.87157 35.7927 1.94142 34.4305 1.49317C33.1096 1.05847 31.6656 0.930723 30.2891 1.03363C28.111 1.19647 25.5706 1.77532 24.5226 3.99659C23.8713 5.377 23.2797 7.0577 22.9892 8.57821C22.9305 8.88523 22.8964 8.83665 22.6747 8.6468C22.0273 8.09241 21.2017 8.08165 20.3877 7.99522C19.357 7.88577 18.3736 7.82175 17.3537 8.03637C15.2979 8.46901 12.5863 9.31816 11.5872 11.4657C11.2314 12.2304 10.8512 13.4175 10.827 14.2778C10.8188 14.5713 10.0343 13.2957 9.8048 13.0981C8.57892 12.0424 6.01875 12.8868 4.71976 13.3176C3.04802 13.872 1.88528 14.9848 1.39089 16.7469Z"
      fill="white"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
