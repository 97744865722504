import { getCategories, getCategory, getPage, search } from "lib/api/faqs";
import { useQuery } from "react-query";

const DEFAULTS = { enabled: true };
export function useCategories({ parentRef, enabled } = DEFAULTS) {
  return useQuery("knowledge/categories", () => getCategories(parentRef), {
    enabled,
  });
}

export function useCategory({ categoryRef, enabled } = DEFAULTS) {
  return useQuery(
    `knowledge/categories/${categoryRef}`,
    () => getCategory(categoryRef),
    {
      enabled,
    }
  );
}

export function useFaqsPage({ pageRef, enabled } = DEFAULTS) {
  return useQuery(`knowledge/pages/${pageRef}`, () => getPage(pageRef), {
    enabled,
  });
}
