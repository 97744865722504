import { Box, useMediaQuery } from "@mui/material";
import { Forest } from "components/icons/Forest";
import { Modal } from "components/layouts/Modal";
import { useTranslation } from "react-i18next";
import { RoleSelector } from "components/shared/RoleSelector";
import { SuperCard } from "components/SuperCard";

export const ModalContractTariff = ({ ...props }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const footerHeight = isMobile ? "89px" : "180px";
  const footerWidth = isMobile ? "353px" : "720px";

  return (
    <Modal title={t("common.contract")} {...props}>
      <Box
        sx={(theme) => ({
          position: "relative",
          bgcolor: "secondary.main",
          width: isMobile ? "100%" : "720px",
          minHeight: isMobile ? "calc(100% - 48px)" : "calc(100vh - 224px)",
          borderBottom: `${isMobile ? "6px" : "16px"} solid ${
            theme.palette.grey[900]
          }`,
        })}
      >
        <SuperCard sx={{ m: 6 }}>
          <RoleSelector
            {...props}
            sx={{
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
            buttonsLayout={isMobile ? "column" : "row"}
          />
        </SuperCard>
        <Box
          sx={{
            position: "absolute",
            bottom: "-4px",
            height: footerHeight,
            left: isMobile ? `calc((100vw - ${footerWidth})/2)` : 0,
          }}
        >
          <Forest width={footerWidth} height={footerHeight} />
        </Box>
      </Box>
    </Modal>
  );
};
