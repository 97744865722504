// vendor
import { useEffect, useRef, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import MuiSearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

// source
import { search as searchFaqs } from "lib/api/faqs";

export default function SearchInput({ pages, setPages }) {
  const { t } = useTranslation();
  const [pattern, setPattern] = useState("");

  const timeout = useRef();
  useEffect(() => {
    clearTimeout(timeout.current);

    if (pattern.length <= 3) {
      setPages();
    } else {
      timeout.current = setTimeout(() => {
        searchFaqs({ pattern, parent: "faqs" }).then((pages) => setPages(pages));
      }, 200);
    }
  }, [pattern]);

  useEffect(() => {
    if (!pages && pattern.length > 3) setPattern("");
  }, [pages]);

  return (
    <OutlinedInput
      className="faqs-search-input"
      variant="outlined"
      placeholder={t("faqs.search.placeholder")}
      sx={{ width: "100%" }}
      value={pattern}
      onChange={(ev) => setPattern(ev.target.value)}
      endAdornment={
        <InputAdornment position="end">
          <MuiSearchIcon edge="end" />
        </InputAdornment>
      }
    />
  );
}
