import InfoIcon from "@mui/icons-material/Info";
import { IconButton } from "@mui/material";
import { TariffMoreInfo } from "components/modals/TariffMoreInfo";
import { useState } from "react";
export const TariffInfo = ({ tariffCode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <TariffMoreInfo
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        tariffCode={tariffCode}
      />
      <IconButton
        sx={{
          p: 0,
          color: "inherit",
        }}
        onClick={() => setIsModalOpen((isOpen) => !isOpen)}
        disabled={!Boolean(tariffCode)}
        aria-label="close_modal"
      >
        <InfoIcon sx={{ fontSize: "1.5rem" }} />
      </IconButton>
    </>
  );
};
