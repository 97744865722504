import React from "react";
import { Box, Typography } from "@mui/material";
import { DialogSuccesForm } from "components/dialogs/DialogSuccesForm";
import { Button } from "components/Button";
import { SmallCloud } from "components/icons/Clouds";
import { BigCloud } from "components/icons/Clouds";
import { Forest } from "components/icons/Forest";
import { useTranslation } from "react-i18next";
import { theme } from "theme";

const FormSucces = ({ name, phone, schedule, onClose }) => {
  const { t } = useTranslation();
  const maskPhone = (phone) => {
    const visibleDigits = phone.slice(-3);
    return `***${visibleDigits}`;
  };

  const formatSchedule = (schedule) => {
    const startHour = parseInt(schedule.slice(0, 2), 10);
    const endHour = parseInt(schedule.slice(2), 10);
    return `${startHour} a ${endHour} horas`;
  };
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.sand.main,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        padding: "80px 0 100px 0",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "30px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            transform: "translate(-50%, -50%)",
          }}
        >
          <SmallCloud />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            transform: "translate(50%, -50%)",
          }}
        >
          <BigCloud />
        </Box>
        <Box
          sx={{
            width: "300px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h3" gutterBottom>
            {t("succes_form.thank_you_title")} {name}
          </Typography>
          <Typography variant="body" gutterBottom>
            {t("succes_form.message_call")}{" "}
            <strong>
              {t("succes_form.message_phone")} {maskPhone(phone)}
            </strong>
            {schedule && (
              <>
                {" "}
                {t("succes_form.message_schecule")}{" "}
                <strong>{formatSchedule(schedule)}</strong>
              </>
            )}
            .
          </Typography>
          <Typography variant="body" gutterBottom>
            {t("succes_form.final_message")}
          </Typography>
        </Box>
        <DialogSuccesForm />
      </Box>
      <Box sx={{ mt: 3, mb: 5, width: "300px" }}>
        <Button fullWidth onClick={onClose}>
          {t("succes_form.close_button")}
        </Button>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: -0.5,
          left: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Forest />
      </Box>
    </Box>
  );
};

export default FormSucces;
