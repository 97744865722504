// vendor
import Box from "@mui/material/Box";

// source
import { Spinner } from "components/Spinner";

export default function PageContent({ content, ref, isActive, isLoading }) {
  if (!isActive) return null;
  if (isLoading)
    return (
      <Box mt={8} mb={4}>
        <Spinner />
      </Box>
    );
  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
}
