import { useEffect, useMemo, useState } from "react";
import { BandwithSelector } from "../internet/BandwithSelector";
import { TechnologySelector } from "../internet/TechnologySelector";
import { MobilesSelector } from "./MobilesSelector";
import { getPackOffers } from "screens/Tariffs/utils/tariffsReducers";
import { FloatingCard } from "components/layouts/FloatingCard";
import { CardContractInfo } from "./CardContractInfo";
import { Box } from "@mui/material";
import { DialogHelp } from "components/dialogs/DialogHelp";

export const PacksTariffPicker = ({
  packs,
  onChange,
  onContract,
  initialPackCode,
}) => {
  const initialPack = useMemo(() => {
    return packs.find(({ code }) => code === initialPackCode);
  }, [packs]);
  const [packOffers, setPackOffers] = useState(
    getPackOffers(packs, initialPack)
  );
  const [selectPackOffer, setSelectPackOffer] = useState(
    packOffers.find(({ isSelected }) => isSelected) || {
      bandwidths: [],
      mobiles: [],
    }
  );

  function setPackOfferSelected(packOffer) {
    const packOffersCopy = [...packOffers];
    packOffersCopy.forEach(
      (item) => (item.isSelected = item.name === packOffer.name)
    );
    if (packOffer.bandwidths.length === 1) {
      packOffer.bandwidths[0].isSelected = true;
    }
    setPackOffers(packOffersCopy);
    setSelectPackOffer(packOffer);
  }

  function setBandwidthSelected(bandwidth) {
    const selectPackOfferCopy = { ...selectPackOffer };
    selectPackOfferCopy.bandwidths.forEach(
      (item) => (item.isSelected = item.bandwidth === bandwidth.bandwidth)
    );
    setSelectPackOffer(selectPackOfferCopy);
  }

  function setMobileSelected(mobiles) {
    const selectPackOfferCopy = { ...selectPackOffer };
    selectPackOfferCopy.mobiles.forEach(
      (item) => (item.isSelected = item.mobiles === mobiles.mobiles)
    );
    setSelectPackOffer(selectPackOfferCopy);
  }

  const matchingPack = useMemo(() => {
    const hasLandLine = selectPackOffer.hasLandLine;
    const bandwidth = selectPackOffer.bandwidths.find(
      (bandwidth) => bandwidth.isSelected
    )?.bandwidth;
    const mobiles = selectPackOffer.mobiles.find(
      (mobile) => mobile.isSelected
    )?.mobiles;
    return packs.find(
      (pack) =>
        pack.has_landline_phone === hasLandLine &&
        pack.fiber_bandwidth === bandwidth &&
        pack.mobiles_in_pack === mobiles
    );
  }, [selectPackOffer]);

  useEffect(() => onChange(matchingPack), [matchingPack]);

  return (
    <>
      <TechnologySelector
        onClick={setPackOfferSelected}
        technologies={packOffers}
      />

      {selectPackOffer.bandwidths.length > 1 && (
        <BandwithSelector
          onClick={setBandwidthSelected}
          bandwidths={selectPackOffer.bandwidths}
        />
      )}
      {selectPackOffer.bandwidths.some(
        (bandwidth) => bandwidth?.isSelected
      ) && (
        <MobilesSelector
          onClick={setMobileSelected}
          mobiles={selectPackOffer.mobiles}
        />
      )}
      {matchingPack && (
        <FloatingCard sx={{ mt: 2 }}>
          <CardContractInfo
            pack={matchingPack}
            onContract={onContract}
            showInfo={true}
            showContract={true}
          />
          <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <DialogHelp origin="tariffs" />
          </Box>
        </FloatingCard>
      )}
    </>
  );
};
