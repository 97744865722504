import styled from "@emotion/styled";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const dotSize = 32;

const Root = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const DotRoot = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  alignItems: "center",
  padding: "0px 8px",
});

const LabelRoot = styled("div")(({ theme }) => ({
  "display": "flex",
  "flexDirection": "row",
  "justifyContent": "space-between",
  "width": "100%",
  "marginTop": theme.spacing(1),
  "& > div": {
    "width": dotSize,
    "height": dotSize,
    "display": "flex",
    "justifyContent": "center",
    "&:first-child": {
      justifyContent: "flex-start",
    },
    "&:last-child": {
      justifyContent: "flex-end",
    },
  },
}));

const Dot = styled("div")(({ theme, isCompleted }) => ({
  display: "flex",
  width: dotSize,
  height: dotSize,
  borderRadius: "100%",
  background: isCompleted ? "#212121" : theme.palette.grey[100],
  border: "1px Solid #212121",
  justifyContent: "center",
  alignItems: "center",
}));

const Line = styled("div")(({ theme, isCompleted }) => ({
  display: "flex",
  flex: 1,
  borderTopWidth: isCompleted ? 2 : 1,
  borderTopStyle: isCompleted ? "solid" : "dashed",
  borderTopColor: isCompleted
    ? theme.palette.grey[900]
    : theme.palette.grey[800],
}));

export const Stepper = ({ currentStep, steps, title }) => {
  const { t } = useTranslation();

  const activeIndex = steps.findIndex(({ key }) => key === currentStep);

  const isCompleted = (index) => index <= activeIndex;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {title && <Typography variant="body_bold">{t(title)}</Typography>}
      <Root>
        <DotRoot>
          {steps.map(({ key }, index) => (
            <>
              <Dot key={key} isCompleted={isCompleted(index)}>
                {isCompleted && (
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3257_24644)">
                      <path
                        d="M6 11.2812L13.0625 4.21875L14 5.15625L6 13.1562L2.28125 9.4375L3.21875 8.5L6 11.2812Z"
                        fill="#F1F1F1"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3257_24644">
                        <rect
                          width="16"
                          height="16"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </Dot>
              {index < steps.length - 1 && (
                <Line
                  key={`line-${key}`}
                  isCompleted={isCompleted(index + 1)}
                />
              )}
            </>
          ))}
        </DotRoot>
        <LabelRoot>
          {steps.map(({ label, key }, index) => (
            <div>
              <Typography
                variant={"body_small_bold"}
                key={key}
                sx={{
                  color: "grey.900",
                  textTransform: "uppercase",
                }}
              >
                {t(label)}
              </Typography>
            </div>
          ))}
        </LabelRoot>
      </Root>
    </Box>
  );
};
